

















































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { More } from "@/mixin/more";
import StorageDb from "@/util/storage";
import Bread from "@/components/bread/index.vue";
import { OtherModule } from "@/store/other";
import { api_subject } from "@/api";
import { numFormat } from "@/util/string";
@Component({
  components: {
    Bread,
  },
})
export default class extends More(api_subject.get_list,false) {
  numFormat = numFormat
  index = 0
  search = ''
  new_list: { to?: string; title: string }[] = [];
  filter: { position: number | string; search: string} = {
    position: "",
    search: ""
  };

  position_id = 0;

  changePosition(res: string) {
    this.$router.push(`/subject/${res}/`);
  }

  get is_mobile() {
    return OtherModule.is_mobile;
  }

  get position() {
    let arr = [
      {label:'All',value:0}
    ]
    arr = arr.concat(StorageDb.getLocal("position"))
    return arr;
  }

  move(id: number,position_id: number) {
    this.$router.push(`/subject/${position_id}/info/${id}`);
  }

  @Watch("$route", { immediate: true })
  watch_route(res: any) {
    this.position_id = +res.params.position_id
    let position = [
      {label:'All',value:0}
    ]
    position = position.concat(StorageDb.getLocal("position"))
    let index = position.findIndex(
      (v: any) => v.value === +res.params.position_id
    );
    this.index = index
    this.new_list = [
      {
        to: `/subject/${res.params.position_id}/list`,
        title: "응시직렬",
      },
      {
        title: position[index].label,
      },
    ];
    this.filter.position = res.params.position_id!=0?(+res.params.position_id):'';
    if(res.query.search){
      this.filter.search = res.query.search
    }
    this.clear_list();
    this.search = this.filter.search
  }

  searchSome(){
    this.clear_list();
    this.search = this.filter.search
  }
}
